<template>
	<b-row>
		<b-col md="12 mb-30">
			<b-card>
				<b-row>
					<b-col>
						<a v-b-tooltip.hover href="/loan/applicationApproval">
							<i class="i-Arrow-Left-2 text-10" />
							{{ $t("Back to List", "Back to List") }}
						</a>
					</b-col>
					<b-col class="text-right">
						<div v-show="loading"><strong>Loading...</strong></div>
						<div v-if="verification" v-show="!loading">
							<b-button v-show="isRolesUnVerf" variant="danger" class="mr-2" @click="rejectAction()"> {{ $t("Reject", "Reject") }} </b-button>
							<b-button v-show="isRolesVerf" variant="primary" @click="onApproval()">
								{{ $t("Approve Loan Application", "Approve Loan Application") }}
							</b-button>
						</div>
						<div v-else>
							<b-button v-show="isStatusApplication === 143" class="ml-2" pill variant="primary btn-dis-hover" disabled>
								<b>{{ $t("Approved", "Approved") }}</b>
							</b-button>
							<b-button v-show="isStatusApplication === 144" pill variant="danger btn-dis-hover" disabled>
								<b>{{ $t("Rejected", "Rejected") }}</b>
							</b-button>
						</div>
					</b-col>
				</b-row>
				<form-wizard ref="wizard" title subtitle :color="colorwizard" step-size="xs">
					<template #step="props">
						<wizard-step :key="props.tab.title" :tab="props.tab" :transition="props.transition" :index="props.index" />
					</template>
					<tab-content :title="$t('Application Data', 'Application Data')" :icon="isIconStatus" />
					<tab-content :title="$t('Data Verification', 'Data Verification')" :icon="isIconStatus" />
					<tab-content :title="$t('Approval by Lender', 'Approval by Lender')" :icon="isIconStatus" />
					<template #footer>
						<div class="wizard-footer-left">
							<wizard-button hidden="true"> Previous </wizard-button>
						</div>
						<div class="wizard-footer-right">
							<wizard-button hidden="true"> Next </wizard-button>
							<wizard-button hidden="true" />
						</div>
					</template>
					<div v-show="isStatusApplication === 144" class="ml-1 mr-1 alert alert-danger">
						<b>{{ $t("Reject Note", "Reject Note") }} :</b> {{ statusnote }}
					</div>
				</form-wizard>
				<!-- General Data -->
				<div>
					<b-card border-variant="primary" header="Loan Application" header-bg-variant="primary" header-text-variant="white" align="left">
						<b-tabs active-nav-item-class="nav nav-tabs">
							<b-tab :title="$t('Application Detail', 'Application Detail')" :active="activeTabDetail">
								<!-- Application Detail-->
								<!-- Personal Data Section -->
								<b-card
									border-variant="primary"
									:header="$t('Personal Data', 'Personal Data')"
									header-bg-variant="primary"
									header-text-variant="white"
									align="left"
									no-body
									class="mb-2"
								/>
								<form-personal :key="result.length + id" :result="result" />
								<div style="height: 1.25rem" />

								<!-- Family Section -->
								<b-card
									border-variant="primary"
									:header="$t('Family', 'Family')"
									header-bg-variant="primary"
									header-text-variant="white"
									align="left"
									no-body
									class="mb-2"
								/>
								<form-family :key="result.length + id2" :result="result" />
								<div style="height: 1.25rem" />

								<!-- Certification Section -->
								<b-card
									border-variant="primary"
									:header="$t('Certification', 'Certification')"
									header-bg-variant="primary"
									header-text-variant="white"
									align="left"
									no-body
									class="mb-2"
								/>
								<form-certified :key="result.length + id3" :result="result" />
								<div style="height: 1.25rem" />

								<!-- Address Section -->
								<b-card
									border-variant="primary"
									:header="$t('Address', 'Address')"
									header-bg-variant="primary"
									header-text-variant="white"
									align="left"
									no-body
									class="mb-2"
								/>
								<form-address :key="result.length + id4" :result="result" />
								<div style="height: 1.25rem" />

								<!-- Contact Section -->
								<b-card
									border-variant="primary"
									:header="$t('Contact', 'Contact')"
									header-bg-variant="primary"
									header-text-variant="white"
									align="left"
									no-body
									class="mb-2"
								/>
								<form-contact :key="result.length + id5" :result="result" />
								<div style="height: 1.25rem" />

								<!-- Bank Account Section -->
								<b-card
									border-variant="primary"
									:header="$t('Bank Account', 'Bank Account')"
									header-bg-variant="primary"
									header-text-variant="white"
									align="left"
									no-body
									class="mb-2"
								/>
								<form-bank :key="result.length + id6" :result="result" />

								<div style="height: 20px" />
								<!-- Business Data -->
								<form-businness-data :key="result.length + id7" :result="result" />

								<div style="height: 20px" />
								<!-- Document Data -->
								<form-document :key="result.length + id8" :result="result" />

								<div style="height: 1.25rem" />
								<b-card
									border-variant="primary"
									:header="$t('Loan Data', 'Loan Data')"
									header-bg-variant="primary"
									header-text-variant="white"
									align="left"
								>
									<form-loan-data :key="result.length + id9" :result="result" />
									<div style="height: 1.25rem" />
									<form-supplier :key="result.length + id10" :result="result" />
								</b-card>
								<!-- end Application Detail-->
							</b-tab>
							<b-tab :title="$t('Credit Score', 'Credit Score')" :active="activeTabCredit" @click="onCreditScoreLoad">
								<form-credit-score
									:key="result.length + id11"
									:result="result"
									:score="score"
									:prediction="prediction"
									:listing-farm-data="listingFarmData"
									:listing-profile-data="listingProfileData"
									:loading="loading"
								/>
							</b-tab>
						</b-tabs>

						<b-row>
							<b-col class="text-right">
								<div v-show="loading"><strong>Loading...</strong></div>
								<div v-if="verification" v-show="!loading">
									<b-button variant="danger" class="mr-2" @click="rejectAction()"> {{ $t("Reject", "Reject") }} </b-button>
									<b-button variant="primary" @click="onApproval()">
										{{ $t("Approve Loan Application", "Approve Loan Application") }}
									</b-button>
								</div>
								<div v-else>
									<b-button v-show="isStatusApplication === 143" class="ml-2" pill variant="primary btn-dis-hover" disabled>
										<b>{{ $t("Approved", "Approved") }}</b>
									</b-button>
									<b-button v-show="isStatusApplication === 144" pill variant="danger btn-dis-hover" disabled>
										<b>{{ $t("Rejected", "Rejected") }}</b>
									</b-button>
								</div>
							</b-col>
						</b-row>
					</b-card>
				</div>
			</b-card>

			<!--modal-->
			<ktv-modal v-model="modalsFilter" :title="$t('Loan Application Rejected!', 'Loan Application Rejected!')" @close="modalsFilter = false">
				<template #content>
					<vcl-facebook v-show="loading" />
					<div v-show="!loading" class="row">
						<div class="col-12">
							<h3>
								<strong>{{ $t("Why Application rejected?", "Why Application rejected?") }}</strong>
							</h3>
						</div>
						<div class="col-12">
							{{
								$t(
									"Write description of why the loan application form is rejected",
									"Write description of why the loan application form is rejected",
								)
							}}.
						</div>
						<div class="col-12">
							<b-form-textarea v-model="form.inputreject" :placeholder="$t('Enter something', 'Enter something')" rows="7" />
						</div>
						<div class="clearfix">&nbsp;</div>
						<div class="col-12">
							<ktv-button class="col-md-5 offset-md-1" text="Cancel" color="gray-300" style="margin-right: 10px" @click="modalsFilter = false" />
							<ktv-button class="col-md-5" text="Submit" color="primary" @click="SubmitrejectAction" />
						</div>
					</div>
				</template>
				<template #footer />
			</ktv-modal>
		</b-col>
	</b-row>
</template>
<script>
	import { findWhere, map } from "underscore"
	import { KtvButton, KtvModal } from "@/components"
	import { mapActions, mapGetters, mapState } from "vuex"
	import { v4 as uuidv4 } from "uuid"

	import FormAddress from "../../application/form/FormAddress"
	import FormBank from "../../application/form/FormBank"
	import FormBusinnessData from "../../application/form/FormBusinnessData"
	import FormCertified from "../../application/form/FormCertified"
	import FormContact from "../../application/form/FormContact"
	import FormCreditScore from "./FormCreditScore"
	import FormDocument from "../../application/form/FormDocument"
	import FormFamily from "../../application/form/FormFamily"
	import FormLoanData from "./FormLoanData"
	import FormPersonal from "../../application/form/FormPersonal"
	import FormSupplier from "../../application/form/FormSupplier"

	const columnsBusinessList = [
		{
			label: "Business",
			field: "BusName",
			thClass: "text-left",
		},
		{
			label: "Status",
			field: "BusStatus",
			thClass: "text-left",
		},
		{
			label: "Size",
			field: "BusSize",
			thClass: "text-left",
		},
		{
			label: "Production",
			field: "BusProduction",
			thClass: "text-left",
		},
		{
			label: "Lat",
			field: "BusLat",
			thClass: "text-left",
		},
		{
			label: "Long",
			field: "BusLong",
			thClass: "text-left",
		},
		{
			label: "Action",
			field: "ButtonAct",
			html: true,
			tdClass: "text-center",
			thClass: "text-center",
			sortable: false,
		},
	]

	import getRolesWhile from "../../../../../libs/getRoles.js"
	export default {
		components: {
			FormPersonal,
			FormFamily,
			FormCertified,
			FormAddress,
			FormContact,
			FormBank,
			FormLoanData,
			FormSupplier,
			FormDocument,
			FormBusinnessData,
			FormCreditScore,
			KtvButton,
			KtvModal,
		},
		data() {
			return {
				activeTabCredit: false,
				activeTabDetail: true,
				colorwizard: "#54A7DC",
				columnsBusinessList: columnsBusinessList,
				form: {
					inputreject: null,
				},
				genderOptions: [],
				id: "",
				id10: "",
				id11: "",
				id2: "",
				id3: "",
				id4: "",
				id5: "",
				id6: "",
				id7: "",
				id8: "",
				id9: "",
				isIconStatus: "i-Yes",
				isRolesUnVerf: false,
				isRolesVerf: false,
				isStatusApplication: null,
				listingFarmData: [],
				listingProfileData: [],
				loading: true,
				modalsFilter: false,
				prediction: "",
				result: [],
				score: "0",
				statusnote: null,
				verification: true,
			}
		},
		computed: {
			...mapGetters({
				loggedInUser: "AUTHDATA/loggedInUser",
				getLoanID: "LOAN/getLoanID",
				getSummaryResult: "LOAN/getSummaryResult",
			}),
			...mapState("OTHERS", ["genderList"]),
		},
		watch: {
			genderList: {
				deep: true,
				handler() {
					this.getGenderOptions()
				},
				immediate: true,
			},
		},
		async mounted() {
			this.$refs.wizard.nextTab()
			this.isRolesVerf = this.getRolesWhile({ modulename: "/loan", urlinfo: "/loan/applicationApproval", aksifungsi: "approval", bool: true }) // get roles
			this.isRolesUnVerf = this.getRolesWhile({ modulename: "/loan", urlinfo: "/loan/applicationApproval", aksifungsi: "reject", bool: true }) // get roles
			await this.FetchData()
		},
		beforeMount() {
			this.id = uuidv4()
			this.id2 = uuidv4()
			this.id3 = uuidv4()
			this.id4 = uuidv4()
			this.id5 = uuidv4()
			this.id6 = uuidv4()
			this.id7 = uuidv4()
			this.id8 = uuidv4()
			this.id9 = uuidv4()
			this.id10 = uuidv4()
			this.id11 = uuidv4()
		},
		created() {
			this.getGenderList()
		},
		methods: {
			...getRolesWhile,
			...mapActions({
				ActionGetDetailApproval: "LOAN/ActionGetDetailApproval",
				ActionReject: "LOAN/ActionReject",
				ActionApproval: "LOAN/ActionApproval",
				ActionCreditScore: "LOAN/ActionCreditScore",
				getGenderList: "OTHERS/getGenderList",
			}),
			getGenderOptions() {
				this.genderOptions = map(this.genderList, (gender) => {
					return {
						text: gender.value.charAt(0).toUpperCase() + gender.value.slice(1),
						value: parseInt(gender.code, 10),
					}
				})
			},
			toPlainString(num) {
				return ("" + +num).replace(/(-?)(\d*)\.?(\d*)e([+-]\d+)/, function (a, b, c, d, e) {
					return e < 0 ? b + "0." + Array(1 - e - c.length).join(0) + c + d : b + c + d + Array(e - d.length + 1).join(0)
				})
			},
			onCreditScoreLoad() {
				this.loading = true
				this.ActionCreditScore().then((e) => {
					this.loading = false
					let profileData = []
					let farmData = []
					if (e.dataCreditScore !== null) {
						const sc = e.loanScoring
						this.score = e.loanScoring === null ? "0" : parseFloat(this.toPlainString(sc)).toFixed(4)
						this.prediction = e.prediction
						Object.keys(e.dataCreditScore.profileData).forEach((key) => {
							const k = key.replace(/([a-z])([A-Z])/g, "$1 $2")

							if (key === "isCertification") {
								const getValue = (isCertification) => {
									const matchedIsCertification = findWhere(
										[
											{ text: "No", value: 0 },
											{ text: "Yes", value: 1 },
										],
										{ value: parseInt(isCertification, 10) },
									)
									return matchedIsCertification.text ? matchedIsCertification.text : "-"
								}

								profileData.push({
									text: k.charAt(0).toUpperCase() + k.slice(1).toLowerCase(),
									value: e.dataCreditScore.profileData[key] ? getValue(e.dataCreditScore.profileData[key]) : "-",
								})
							} else if (key === "gender") {
								const getGender = (gender) => {
									const matchedGender = findWhere(this.genderOptions, { value: parseInt(gender, 10) })
									return matchedGender.text ? matchedGender.text : "-"
								}

								profileData.push({
									text: k.charAt(0).toUpperCase() + k.slice(1).toLowerCase(),
									value: e.dataCreditScore.profileData[key] ? getGender(e.dataCreditScore.profileData[key]) : "-",
								})
							} else {
								profileData.push({
									text: k.charAt(0).toUpperCase() + k.slice(1).toLowerCase(),
									value: e.dataCreditScore.profileData[key] ? e.dataCreditScore.profileData[key] : "-",
								})
							}
						})

						Object.keys(e.dataCreditScore.farmData).forEach((key) => {
							const k = key.replace(/([a-z])([A-Z])/g, "$1 $2")
							const obj = {
								text: k.charAt(0).toUpperCase() + k.slice(1).toLowerCase(),
								value: e.dataCreditScore.farmData[key] ? e.dataCreditScore.farmData[key] : "-",
							}
							farmData.push(obj)
						})
					}
					this.listingProfileData = profileData
					this.listingFarmData = farmData
				})
			},
			async FetchData() {
				this.$store.commit("LOAN/setLoanID", this.$route.params.id)
				const res = await this.ActionGetDetailApproval()
				this.loading = false
				this.result = res
				if (res) {
					this.loading = false
					switch (res[0].statusId) {
					case 144:
						this.isStatusApplication = 144 // "rejected"
						this.colorwizard = "#EE180A"
						this.$refs.wizard.nextTab()
						this.verification = false
						this.statusnote = res[0].statusNote
						break
					case 143:
						this.isStatusApplication = 143 // "approved"
						this.verification = false
						this.activeTabCredit = false
						this.$refs.wizard.nextTab()
						break
					default:
						this.isStatusApplication = ""
					}
				}
			},
			onApproval() {
				this.$swal({
					type: "warning",
					title: this.$t("Confirmation", "Confirmation"),
					text: this.$t("Are you sure you want to approve the data?", "Are you sure you want to approve the data?"),
					showConfirmButton: true,
					showCancelButton: true,
					confirmButtonText: this.$t("Approve", "Approve"),
					cancelButtonText: this.$t("Cancel", "Cancel"),
				}).then((isConfirm) => {
					if (isConfirm.value === true) {
						this.$swal.fire({
							title: `${this.$t("Loading", "Loading")}...`,
							html: this.$t("Data will be updated", "Data will be updated"),
							allowOutsideClick: false,
							onBeforeOpen: () => {
								this.$swal.showLoading()
							},
						})
						this.ActionApproval(this.getSummaryResult).then(() => {
							this.modalsFilter = false
							this.isStatusApplication = 143 // "approved"
							this.verification = false
							this.activeTabCredit = false
							this.loading = false
							this.$refs.wizard.nextTab()
						})
					}
				})
			},
			rejectAction() {
				this.modalsFilter = true
				this.loading = false
			},
			SubmitrejectAction() {
				if (this.form.inputreject === null) {
					this.$swal(
						`${this.$t("Warning", "Warning")}!`,
						this.$t("Explain why the loan application form is rejected", "Explain why the loan application form is rejected"),
						"error",
					)
				} else {
					this.loading = true
					const payload = {
						loanApplicationId: this.getLoanID,
						statusNote: this.form.inputreject,
						createdBy: this.loggedInUser.UserId,
						status: "rejected",
					}
					this.ActionReject(payload).then(() => {
						this.modalsFilter = false
						this.$refs.wizard.nextTab()
						this.colorwizard = "#EE180A"
						this.isStatusApplication = 144 // "rejected"
						this.verification = false
						this.isIconStatus = "i-Close"
						this.loading = false
					})
				}
			},
		},
	}
</script>
<style>
	.modal-header {
		background: #54a7dc;
	}
	.modal-title {
		color: #fff;
	}
	.modal-footer {
		display: none;
	}
</style>
