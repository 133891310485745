<template>
	<div>
		<!-- Loan Packet Section -->
		<b-card
			border-variant="primary"
			:header="$t('Loan Package', 'Loan Package')"
			header-bg-variant="primary"
			header-text-variant="white"
			align="left"
			no-body
			class="mb-2"
		/>
		<vcl-facebook v-show="loading" />
		<b-row v-show="!loading">
			<b-form-group class="col-md-12 mb-2" :label="$t('Loan Package', 'Loan Package')">
				<b-form-input v-model="form.LoanPackage" type="text" readonly />
			</b-form-group>

			<!-- Cash only --->
			<b-form-group v-show="statusLoan" class="col-md-6 mb-2" :label="$t('Cash Loan Request', 'Cash Loan Request')">
				<b-form-input v-model="form.CashLoan" type="text" readonly />
			</b-form-group>
			<b-form-group v-show="statusLoan" class="col-md-6 mb-2" :label="$t('Cash Loan Approved', 'Cash Loan Approved')">
				<b-form-input
					v-model="form.CashLoanApproved"
					:readonly="isDisabled"
					type="text"
					style="background: #fff"
					@keyup="onCashLoanEnter"
					@focus="onFocusCashLoanRequest"
					@blur="onBlurCashLoanRequest"
				/>
			</b-form-group>

			<!-- product only --->
			<b-form-group v-show="statusProduct" class="col-md-6 mb-2" :label="$t('Product Loan Request', 'Product Loan Request')">
				<b-form-input v-model="form.CashProduct" type="text" readonly />
			</b-form-group>
			<b-form-group v-show="statusProduct" class="col-md-6 mb-2" :label="$t('Product Loan Approved', 'Product Loan Approved')">
				<b-form-input v-model="form.CashProductApproved" type="text" readonly />
			</b-form-group>
			<!-- end product only --->

			<b-form-group v-show="statusLoan" class="col-md-2 mb-2" :label="$t('Number of Agri Inputs', 'Number of Agri Inputs')">
				<b-form-input v-model="form.NumberOfAgriinput" type="text" readonly />
			</b-form-group>
		</b-row>
		<b-card
			v-show="!loading"
			border-variant="primary"
			:header="$t('Product Detail', 'Product Detail')"
			header-bg-variant="primary"
			header-text-variant="white"
			align="left"
			no-body
			class="mb-2"
		/>
		<b-row v-for="(row, index) in ProductDashboarddetails" :key="index">
			<b-col cols="4">
				<b-img center :src="imgsdata(row.image)" v-bind="propsImageProduct" />
			</b-col>
			<b-col cols="8">
				<b-form>
					<b-form-group class="col-md-12 mb-2" :label="$t('Agri Inputs Name', 'Agri Inputs Name')" label-for="input-1">
						<b-form-input :value="row.name" type="text" readonly />
					</b-form-group>
					<b-row>
						<b-form-group class="col-md-3 mb-2 ml-3" :label="$t('Quantity Request', 'Quantity Request')" label-for="input-1">
							<b-form-input :value="row.quantity" type="number" min="0" readonly />
						</b-form-group>
						<b-form-group class="col-md-3 mb-2" :label="$t('Price (/unit)', 'Price (/unit)')" label-for="input-1">
							<b-form-input :value="row.salePrice" type="text" readonly />
						</b-form-group>
					</b-row>
					<b-form-group class="col-md-3 mb-2" :label="$t('Quantity Approved', 'Quantity Approved')" label-for="input-1">
						<b-form-input v-model="row.quantityApprove" type="number" style="background: #fff" @keyup="onQtyApprovEnter(index)" />
					</b-form-group>
				</b-form>
			</b-col>
		</b-row>
	</div>
</template>
<script>
	import { mapActions, mapGetters } from "vuex"
	import _ from "lodash"

	export default {
		props: {
			result: {
				type: Array,
				required: true,
			},
		},
		data() {
			return {
				loading: true,
				propsImageProduct: { width: 200 },
				ProductDashboarddetails: [],
				List: [],
				form: {
					LoanPackage: "",
					CashLoan: "",
					CashLoanApproved: "",
					CashProduct: "",
					CashProductApproved: "",
					NumberOfAgriinput: "",
					ProductDashboard: [],
				},
				statusLoan: false,
				statusProduct: false,
				isDisabled: false,
			}
		},
		computed: {
			...mapGetters({
				loggedInUser: "AUTHDATA/loggedInUser",
				getCashProductApproved: "LOAN/getCashProductApproved",
			}),
		},
		mounted() {
			this.getPocket()
		},
		methods: {
			...mapActions({}),
			imgsdata(image) {
				let img = ""
				if (image !== null) {
					const partsOfStr = image.split(",")
					img = "https://farmretail.s3-ap-southeast-1.amazonaws.com/produk/" + partsOfStr[0]
				}
				return img
			},
			getPocket() {
				/**
				 * Waiting for a render data before result done
				 */

				if (this.result.length > 0) {
					this.loading = false
					this.isDisabled = !!(this.result[0].statusId === 143 || this.result[0].statusId === 144)

					this.form.LoanPackage = this.result[0].productDashboard.packageName
					this.form.CashLoan = this.formatPrice(this.result[0].loanCashAmount)
					this.form.CashProduct = this.formatPrice(this.result[0].loanProductAmount)
					this.form.NumberOfAgriinput = this.result[0].productDashboard.count
					this.form.CashProductApproved = this.formatPrice(this.result[0].CashLoanApproved)

					if (this.result[0].loanCashAmountApprove === null || this.result[0].loanCashAmountApprove === 0) {
						this.form.CashLoanApproved = this.formatPrice(this.result[0].loanCashAmount)
					} else {
						this.form.CashLoanApproved = this.result[0].loanCashAmountApprove
					}

					if (Object.keys(this.result[0].productDashboard).length > 0) {
						// Product pocket
						if (this.result[0].productDashboard.detail.length > 0) {
							let arrProd = []
							this.result[0].productDashboard.detail.forEach((e) => {
								arrProd.push({
									productId: e.productId,
									name: e.name,
									image: e.image,
									quantity: e.quantity,
									salePrice: this.formatPrice(e.salePrice),
									quantityApprove: e.quantityApprove === 0 ? e.quantity : e.quantityApprove,
								})
							})

							this.ProductDashboarddetails = arrProd

							if (this.result[0].productDashboard.loanPackageTypeId === 156 || this.result[0].productDashboard.loanPackageTypeId === 157) {
								this.statusProduct = true
							}
						}
						// Cash pocket
						if (this.result[0].productDashboard.loanPackageTypeId === 155 || this.result[0].productDashboard.loanPackageTypeId === 157) {
							this.statusLoan = true
						}
					}

					this.onQtyApprovEnter() // data ini akan diload pertama kali, jadi akan menghitung berdasarkan jumlah produk, biar valid.
				}
			},
			/* Handler Calculate Repayment Amount */
			onCalculateRepaymentAmount() {},
			/* Handler onCashLoanEnter */
			onCashLoanEnter: _.debounce(function () {
				if (this.result.length > 0) {
					// Cash Only
					if (this.result[0].productDashboard.loanPackageTypeId === 155 || this.result[0].productDashboard.loanPackageTypeId === 157) {
						if (this.result[0].productDashboard.loanPackageTypeId === 155) {
							this.CalculateCashProduct(155, this.form.CashLoanApproved)
							const jsonResult = {
								loanApplicationId: this.result[0].loanApplicationId,
								totalLoanAmount: this.form.CashLoanApproved.replace(/\./g, ""),
								statusNote: "",
								createdBy: this.loggedInUser.UserId,
								loanPackageId: this.result[0].loanPackageId,
								status: "approved",
								totalProductAmountApprove: 0,
								totalCashAmountApprove: this.form.CashLoanApproved.replace(/\./g, ""),
								product: [],
							}

							this.$store.commit("LOAN/setSummaryResult", jsonResult)
						} else {
							/**
							 * ambil juga product only karena 157 product ada juga
							 */
							let produkDetails = this.ProductDashboarddetails
							let CollectProduct = []
							produkDetails.forEach((val) => {
								let productArray = {
									productId: val.productId,
									quantity: val.quantityApprove === 0 || val.quantityApprove === "0" ? val.quantity : val.quantityApprove,
								}
								CollectProduct.push(productArray)
							})

							this.CalculateCashProduct(157, this.form.CashLoanApproved, CollectProduct)
						}
					}
				}
			}, 1000),
			/* Handler onCashProductEnter */
			onQtyApprovEnter: _.debounce(function () {
				if (this.result.length > 0) {
					// Product Only
					if (this.result[0].productDashboard.detail != null) {
						// Product Only
						let CollectProduct = []
						if (this.result[0].productDashboard.loanPackageTypeId === 156 || this.result[0].productDashboard.loanPackageTypeId === 157) {
							/**
							 * product only
							 */
							let produkDetails = this.ProductDashboarddetails
							produkDetails.forEach((val) => {
								let productArray = {
									productId: val.productId,
									quantity: val.quantityApprove === 0 || val.quantityApprove === "0" ? val.quantity : val.quantityApprove,
									total_price:
										val.quantityApprove === 0 || val.quantityApprove === "0"
											? val.quantity * val.salePrice.replace(/\./g, "")
											: val.quantityApprove * val.salePrice.replace(/\./g, ""),
								}
								CollectProduct.push(productArray)
							})

							const totalProductLoanApprove = CollectProduct.reduce(function (acc, val) {
								return acc + val.total_price
							}, 0)

							this.$store.commit("LOAN/setCashProductApproved", totalProductLoanApprove)

							if (this.result[0].productDashboard.loanPackageTypeId === 156) {
								this.form.CashProductApproved = this.formatPrice(totalProductLoanApprove)
								const jsonResult = {
									loanApplicationId: this.result[0].loanApplicationId,
									statusNote: "",
									totalLoanAmount: totalProductLoanApprove,
									createdBy: this.loggedInUser.UserId,
									loanPackageId: this.result[0].loanPackageId,
									status: "approved",
									totalProductAmountApprove: totalProductLoanApprove,
									totalCashAmountApprove: 0,
									product: CollectProduct,
								}
								this.CalculateCashProduct(156, totalProductLoanApprove)
								this.$store.commit("LOAN/setSummaryResult", jsonResult)
							} else {
								this.form.CashProductApproved = this.formatPrice(totalProductLoanApprove)
								this.CalculateCashProduct(157, totalProductLoanApprove, CollectProduct)
							}
						}
					}
				}
			}, 1000),
			CalculateCashProduct(loanPackageTypeId, total, CollectProduct) {
				if (loanPackageTypeId === 156 || loanPackageTypeId === 155) {
					this.$parent.RecalculateLoan(total)
				}
				if (loanPackageTypeId === 157) {
					// Cash&productonly
					const cash = this.form.CashLoanApproved === "" ? 0 : this.form.CashLoanApproved.toString().replace(/\./g, "")
					const product = this.getCashProductApproved === "" ? 0 : this.getCashProductApproved.toString().replace(/\./g, "")
					const Newtotal = parseFloat(cash) + parseFloat(product)
					this.$parent.RecalculateLoan(Newtotal)

					const jsonResult = {
						loanApplicationId: this.result[0].loanApplicationId,
						totalLoanAmount: Newtotal.toString().replace(/\./g, ""),
						statusNote: "",
						createdBy: this.loggedInUser.UserId,
						loanPackageId: this.result[0].loanPackageId,
						status: "approved",
						totalProductAmountApprove: product,
						totalCashAmountApprove: cash,
						product: CollectProduct,
					}
					this.$store.commit("LOAN/setSummaryResult", jsonResult)
				}
			},
			formatPrice(value) {
				let val = (value / 1).toFixed().replace(".", ",")
				return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
			},
			onFocusCashLoanRequest() {
				if (this.form.CashLoanApproved !== "" && this.form.CashLoanApproved !== 0) {
					this.form.CashLoanApproved = this.form.CashLoanApproved.toString().split(".").join("")
				}
			},
			onBlurCashLoanRequest: _.debounce(function () {
				if (!isNaN(this.form.CashLoanApproved)) {
					this.form.CashLoanApproved = this.formatPrice(this.form.CashLoanApproved.toString().split(".").join(""))
				} else {
					this.form.CashLoanApproved = this.formatPrice(this.result[0].loanCashAmount)
				}
			}, 500),
		},
	}
</script>
