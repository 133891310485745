<template>
	<div>
		<!-- Loan Data -->
		<vcl-facebook v-show="loading" />
		<b-row v-show="!loading">
			<b-form-group class="col-md-6 mb-2" :label="$t('Currency', 'Currency')">
				<b-form-input v-model="form.Currency" type="text" readonly />
			</b-form-group>
			<b-form-group class="col-md-6 mb-2" :label="$t('Loan ID', 'Loan ID')">
				<b-form-input v-model="form.LoanID" type="text" readonly />
			</b-form-group>
			<b-form-group class="col-md-6 mb-2" :label="$t('Apply Date', 'Apply Date')">
				<b-form-input v-model="form.ApplyDate" type="text" readonly />
			</b-form-group>
			<b-form-group class="col-md-6 mb-2" :label="$t('Amount', 'Amount')">
				<b-form-input v-model="form.AmountLoan" type="text" readonly />
			</b-form-group>
			<b-form-group class="col-md-6 mb-2" :label="$t('Margin %', 'Margin %')">
				<b-row>
					<b-form-input v-model="form.Margin" type="text" readonly class="col-md-2 ml-3" />
					<b-form-input v-model="form.Margin_rp" type="text" readonly class="col-md-4 ml-2" />
				</b-row>
			</b-form-group>
			<b-form-group class="col-md-6 mb-2" :label="$t('Provision %', 'Provision %')">
				<b-row>
					<b-form-input v-model="form.Provisi" type="text" readonly class="col-md-2 ml-3" />
					<b-form-input v-model="form.Provisi_rp" type="text" readonly class="col-md-4 ml-2" />
				</b-row>
			</b-form-group>
			<b-form-group class="col-md-6 mb-2" :label="$t('Total Repayment', 'Total Repayment')">
				<b-form-input v-model="form.TotalRepayment" type="text" readonly />
			</b-form-group>
			<b-form-group class="col-md-6 mb-2" :label="$t('Tenor', 'Tenor')">
				<b-form-input v-model="form.Tenor" type="text" readonly />
			</b-form-group>
			<b-form-group class="col-md-6 mb-2" :label="$t('Due Date', 'Due Date')">
				<b-form-input v-model="form.DueDate" type="text" readonly />
			</b-form-group>
			<b-form-group class="col-md-6 mb-2" :label="$t('Number of Installment', 'Number of Installment')">
				<b-form-input v-model="form.loanNumberOfInstallment" type="text" readonly />
			</b-form-group>
			<b-form-group class="col-md-6 mb-2" :label="$t('Amount of Payment Period', 'Amount of Payment Period')">
				<b-form-input v-model="form.NumberOfPayment" type="text" readonly />
			</b-form-group>
		</b-row>

		<div style="height: 1.25rem" />
		<form-pocket :result="result" />
	</div>
</template>
<script>
	import FormPocket from "./FormPocket"
	import { mapGetters, mapActions } from "vuex"
	export default {
		components: {
			FormPocket,
		},
		props: {
			result: {
				type: Array,
				required: true,
			},
		},
		data() {
			return {
				loading: true,
				ProductDashboarddetails: [],
				form: {
					LoanID: "",
					ApplyDate: "",
					AmountLoan: "",
					Margin: "",
					Margin_rp: "Rp.0",
					Provisi: "",
					Provisi_rp: "Rp.0",
					TotalRepayment: "",
					Tenor: "",
					loanNumberOfInstallment: "",
					NumberOfPayment: "",
				},
			}
		},
		computed: {
			...mapGetters({
				loggedInUser: "AUTHDATA/loggedInUser",
			}),
		},
		mounted() {
			this.getLoanData()
		},
		methods: {
			...mapActions({}),
			getLoanData() {
				/**
				 * Waiting for a render data before result done
				 */
				if (this.result.length > 0) {
					this.loading = false
					this.form.LoanID = this.result[0].loanApplicationId
					this.form.ApplyDate = this.result[0].loanDate
					this.form.Margin = this.result[0].loanMargin
					this.form.Tenor = this.result[0].loanTenor
					this.form.Currency = this.result[0].currency
					this.form.loanNumberOfInstallment = this.result[0].loanNumberOfInstallment
					this.RecalculateLoan(this.result[0].loanAmount)

					// Digunakan untuk Cash Only
					if (this.result[0].productDashboard.loanPackageTypeId === 155) {
						const jsonResult = {
							loanApplicationId: this.result[0].loanApplicationId,
							statusNote: "",
							totalLoanAmount: this.result[0].loanAmount,
							createdBy: this.loggedInUser.UserId,
							loanPackageId: this.result[0].loanPackageId,
							status: "approved",
							totalProductAmountApprove: 0,
							totalCashAmountApprove: this.result[0].loanCashAmount,
							product: [],
						}
						this.$store.commit("LOAN/setSummaryResult", jsonResult)
					}

					// Digunakan untuk Product Only
					if (this.result[0].productDashboard.loanPackageTypeId === 156) {
						let CollectProduct = []
						if (this.result[0].productDashboard.detail != null) {
							this.result[0].productDashboard.detail.forEach((val) => {
								let productArray = {
									productId: val.productId,
									quantity: val.quantity,
								}
								CollectProduct.push(productArray)
							})
							const totalProductLoanApprove = CollectProduct.reduce(function (acc, val) {
								return acc + val.total_price
							}, 0)
							const jsonResult = {
								loanApplicationId: this.result[0].loanApplicationId,
								statusNote: "",
								totalLoanAmount: this.result[0].loanAmount,
								createdBy: this.loggedInUser.UserId,
								loanPackageId: this.result[0].loanPackageId,
								status: "approved",
								totalProductAmountApprove: totalProductLoanApprove,
								totalCashAmountApprove: this.result[0].loanProductAmount,
								loanProductAmountApprove: this.result[0].loanCashAmount,
								product: CollectProduct,
							}
							this.$store.commit("LOAN/setSummaryResult", jsonResult)
						}
					}
				}
			},
			RecalculateLoan(defaultLoanAmount) {
				if (this.result.length > 0) {
					if (defaultLoanAmount === 0) {
						this.form.AmountLoan = this.formatPrice(this.result[0].loanAmount)
					} else {
						this.form.AmountLoan = this.formatPrice(defaultLoanAmount)
					}

					/** Menghitung Jumlah Total provisi */
					this.form.Provisi = this.result[0].loanProvisi
					const jmlProvisi = (this.result[0].loanProvisi * parseFloat(defaultLoanAmount)) / 100
					this.form.Provisi_rp = this.formatPrice(jmlProvisi)

					/** Menghitung Jumlah Total Margin */
					const jmlMargin = (this.result[0].loanMargin * parseFloat(defaultLoanAmount)) / 100
					this.form.Margin_rp = this.formatPrice(jmlMargin)

					/** Menghitung Jumlah Total Repayment */
					const jmlRepayment = parseFloat(defaultLoanAmount) + parseFloat(jmlMargin) + parseFloat(jmlProvisi)
					this.form.TotalRepayment = this.formatPrice(jmlRepayment)
					this.form.DueDate = this.result[0].loanDueDate
					this.form.NumberOfPayment = this.formatPrice(jmlRepayment / this.result[0].loanNumberOfInstallment)
				}
			},
			formatPrice(value) {
				let val = (value / 1).toFixed().replace(".", ",")
				return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
			},
		},
	}
</script>
